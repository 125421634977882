html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

nav,
header {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
h1,
h2,
p,
pre {
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  overflow-wrap: anywhere;
  scrollbar-width: thin;
}

a {
  color: inherit;
  text-decoration: inherit;
}

pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
}

svg {
  display: block;
  vertical-align: middle;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}

.rdp {
  margin: 0px !important;
  --rdp-accent-color: var(--chakra-colors-primary-500) !important;
  --rdp-background-color: var(--chakra-colors-primary-100) !important;
  --rdp-accent-color-dark: var(--chakra-colors-primary-600) !important;
  --rdp-background-color-dark: var(--chakra-colors-primary-900) !important;
}
